import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck, faPen, faTrashCan
} from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './App.css';

function App() {


  // Tasks (ToDo List) State
  const [toDo, setTodo] = useState([]);

  useEffect(() => {
    axios.get('https://apps.aterale.com/todos/tasks/')
      .then(res => {
        setTodo(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  }, []);

  // Temp State
  const [newTask, setNewTask] = useState('');
  const [dueDate, setDueDate] = useState('');

  const [updateData, setUpdateData] = useState('');



  // Add task
  ////////////////////////////////

  const addTask = () => {
    if(newTask) {

      let payload = {
        title: newTask,
        status: false
      }

      if (dueDate) {
        payload.due_date = dueDate;
      }

      // Post new task at the Django API endpoint
      axios.post('https://apps.aterale.com/todos/tasks/', payload)
      .then(res => {
          // update the state with the new task
          setTodo([...toDo, res.data]);
      })
      .catch(error => {
        console.log(error);
      });
      setNewTask('')
      setDueDate('');
    }
  }

  


  // Delete task
  ////////////////////////////////
  const deleteTask = async (id) => {
    console.log(id)
    try {
      await axios.delete(`https://apps.aterale.com/todos/tasks/${id}/`);
      const updatedTasks = await axios.get('https://apps.aterale.com/todos/tasks/');
      setTodo(updatedTasks.data);
    } catch (error) {
      console.log(error);
    }
  }
  





  // Mark task as done or completed
  ////////////////////////////////
  const markDone = async (id) => {
    try {
      const task = await axios.get(`https://apps.aterale.com/todos/tasks/${id}/`);
      const updatedTask = {
        ...task.data,
        status: !task.data.status
      };
      await axios.patch(`https://apps.aterale.com/todos/tasks/${id}/`, updatedTask);
      const newTasks = await axios.get('https://apps.aterale.com/todos/tasks/');
      setTodo(newTasks.data);
    } catch (err) {
      console.log(err);
    }
  }
  
  

  // Cancel update
  ////////////////////////////////
  const cancelUpdate = () => {
    setUpdateData('');
  }
  


  // Change task for update
  ////////////////////////////////
  const changeTask = (e) => {
    let newEntry = {
      id: updateData.id,
      title: e.target.value,
      status: updateData.status ? true : false
    }
    setUpdateData(newEntry);
  }


  
  // Update task
  ////////////////////////////////
  const updateTask = async (id) => {

    let payload = {
      title: updateData.title,
      status: updateData.status,
    }
    if (dueDate) {
      payload.due_date = dueDate;
    }
  
    try {
      const res = await axios.put(`https://apps.aterale.com/todos/tasks/${id}/`, payload)
      setTodo(prevTodo => prevTodo.map(task => task.id === id ? res.data : task))
      setUpdateData('');
      setDueDate('');
    } catch (err) {
      console.log(err);
    }
}



  /*
  
  const updateTask = () => {
    let filterRecords = [...toDo].filter( task => task.id !== updateData.id );
    let updatedObject = [...filterRecords, updateData]
    setTodo(updatedObject);
    setUpdateData('');
  }

  */


  return (
    <div className="container App">

    <br/><br/>
    <h2> To Do List</h2>
    <br/><br/>


    { updateData && updateData ? (
      <>
        {/*Update Task */} 
        <div className="row">
          <div className="col">
            <input
              value={ updateData && updateData.title }
              onChange={ (e) => changeTask(e) } 
              className="form-control form-control-lg"></input>
          </div>
          <div className="col react-datepicker-wrapper">
            <DatePicker
              selected={dueDate}
              onChange={date => setDueDate(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select Due Date"
              className="form-control form-control-lg"
            />
          </div>
          <div className="col-auto">
              <button className="btn btn-lg btn-success mr-20" onClick={() => updateTask(updateData.id)}>
                Update
              </button>
              <button
                onClick={cancelUpdate}
                className="btn btn-lg btn-warning">
                Cancel
              </button>
          </div>
        </div>
        <br/>
      </>
    ) : (
      <>
        {/*Add Task */} 
        <div className="row">
          <div className="col">
            <input
              value={newTask}
              onChange={(e) => setNewTask(e.target.value)}
              className="form-control form-control-lg"
            />
          </div>
          <div className="col react-datepicker-wrapper">
            <DatePicker
              selected={dueDate}
              onChange={date => setDueDate(date)}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select Due Date"
              className="form-control form-control-lg"
            />
          </div>
          <div className="col-auto">
            <button
              className="btn btn-lg btn-success"
              onClick={addTask}
            >
              Add Task
            </button>
          </div>
        </div>
        <br />
      </>
    )}
    

    {/*Display ToDos */} 

    {toDo && toDo.length ? '' : 'No Tasks...' }

    {toDo && toDo
      .map((task, index) => {
        return(
          <React.Fragment key={task.id}>
            <div className="col taskBg">
              <div className={task.status ? 'done' : ''}>
                <span className="taskNumber">{index + 1}</span>
                <span className="taskText">{task.title}</span>
                <span className="taskDueDate">
                  {task.due_date ? new Date(task.due_date).toLocaleDateString() : '' }
                </span>
              </div>
              <div className='iconsWrap'>
                <span title="Completed / Not Completed"
                  onClick={ (e) => markDone(task.id) }>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </span>
                {task.status ? null : (
                  <span title="Edit"
                    onClick={ () => setUpdateData({ 
                      id: task.id,
                      title: task.title,
                      status: task.status ? true : false
                      })}>
                  <FontAwesomeIcon icon={faPen} />
                  </span>
                )}

                <span title="Delete" 
                  onClick={() => deleteTask(task.id)}>
                  <FontAwesomeIcon icon={faTrashCan} />
                </span>
              </div>
            </div>
          </React.Fragment>
        )
      })}

    </div>
  ); 
}

export default App;
